import React from "react";
import Layout from "../components/Layout";

import { graphql } from "gatsby";
import { SliceZone } from "@prismicio/react";
import { components } from "../slices";

export const query = graphql`
  {
    prismicAboutUsPage(data: { title: { text: { eq: "About Us" } } }) {
      data {
        body {
          ... on PrismicAboutUsPageDataBodyTheShort {
            id
            primary {
              description {
                richText
              }
              short_image {
                gatsbyImageData
              }
              title1 {
                text
                richText
              }
            }
            slice_type
          }
          ... on PrismicAboutUsPageDataBodyTheLong {
            id
            slice_type
            items {
              long_image {
                gatsbyImageData
              }
            }
            primary {
              title1 {
                text
                richText
              }
              description {
                richText
              }
            }
          }
        }
      }
    }
  }
`;

const AboutUsPage = (props) => {
  return (
    <Layout>
      <div className="w-full py-24 px-4">
        <div className="flex justify-center">
          <h1 className="md:text-6xl sm:text-5xl text-4xl font-bold py-10 font-font2">
            About Us
          </h1>
        </div>
        <SliceZone
          slices={props.data.prismicAboutUsPage.data.body}
          components={components}
        />
      </div>
    </Layout>
  );
};

export default AboutUsPage;
