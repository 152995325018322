import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { GatsbyImage } from "gatsby-plugin-image";

export const TheShort = (props) => {
  return (
    <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
      <GatsbyImage
        className="w-[350px] mx-auto my-10 md:w-[500px]"
        image={props.slice.primary.short_image.gatsbyImageData}
      />
      <div className="flex flex-col justify-center sm:px-4 md:px-4">
        <h1 className="md:text-3xl sm:text-2xl text-xl font-bold py-4 font-font3">
          <PrismicRichText field={props.slice.primary.title1.richText} />
        </h1>
        <p className="md:text-xl sm:text-xl text-l font-font1">
          <PrismicRichText field={props.slice.primary.description.richText} />
        </p>
      </div>
    </div>
  );
};
