import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { GatsbyImage } from "gatsby-plugin-image";

export const TheLong = (props) => {
  return (
    <div className="w-full py-20">
      <div className="max-w-[1240px] mx-auto grid ">
        <div className="flex flex-col justify-center sm:px-4 md:px-4">
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2 font-font3">
            <PrismicRichText field={props.slice.primary.title1.richText} />
          </h1>
          <p className="md:text-xl sm:text-xl text-l font-font1">
            <PrismicRichText field={props.slice.primary.description.richText} />
          </p>
        </div>
        <div className="mx-auto grid md:grid-cols-2 ">
          {props.slice.items.map((items, i) => {
            return (
              <div key={i} className="p-8">
                <GatsbyImage
                  className="w-[300px] h-[300px] mx-auto my-5 md:px-8 md:w-[400px] md:h-[400px]"
                  image={items.long_image.gatsbyImageData}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
